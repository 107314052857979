import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStatusInterface } from "../../services/exports/Interfaces";

let initialState: AppStatusInterface = {
  hasSeenAppExplainer: false,
};
export default createSlice({
  name: "appStatus",
  initialState,
  reducers: {
    setHasSeenAppExplainer: (
      state: AppStatusInterface,
      action: PayloadAction<boolean>
    ) => {
      try {
        state.hasSeenAppExplainer = action.payload;
      } catch (error) {}
    },
  },
});

export const props = (state: AppStatusInterface) => state;
