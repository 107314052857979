import { Modal } from "@nextui-org/react";
import useGlobalModals from "../../hooks/useGlobalModals";
import Login from "../auth/Login";

export interface LoginModalProps {
  openModal: boolean;
  onLoginSucceed?: () => void;
}

const LoginModal = (): JSX.Element => {
  const { loginModalProps, setLoginModalProps } = useGlobalModals();

  function onClose(): void {
    setLoginModalProps({ openModal: false });
  }

  function onResolve(): void {
    onClose();
    loginModalProps?.onLoginSucceed && loginModalProps.onLoginSucceed();
  }

  return (
    <Modal
      closeButton
      blur
      aria-labelledby="modal-title"
      open={loginModalProps?.openModal}
      onClose={onClose}
    >
      <Modal.Body>
        <Login onResolve={onResolve} />
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
