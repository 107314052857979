import {
  PaginatedStellenAngebotInterface,
  StellenAngebotDetailsInterface,
} from "../exports/Interfaces";
import { Response } from "../exports/Types";
import { Buffer } from "buffer";

export default class ArbeitsAgenturManager {
  public static async findJobs(
    query?: string
  ): Promise<Response<PaginatedStellenAngebotInterface>> {
    const url = "https://rest.arbeitsagentur.de/oauth/gettoken_cc$";
    const options = {
      method: "POST",
      body: new URLSearchParams({
        client_id: "c003a37f-024f-462a-b36d-b001be4cd24a",
        client_secret: "32a39620-32b3-4307-9aa1-511e3d7f48a8",
        grant_type: "client_credentials",
      }),
    };
    return fetch(url, options)
      .then((response) => response.json())
      .then((tokenData: any) => {
        if (typeof URLSearchParams !== "undefined") {
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has("token") && urlParams.get("token") === "TRUE") {
          } else {
            const searchUrl = `https://rest.arbeitsagentur.de/jobboerse/jobsuche-service/pc/v4/jobs?${
              this.formatQuery(query) ?? ""
            }`;
            const searchOptions = {
              headers: {
                OAuthAccessToken: tokenData.access_token,
              },
              method: "GET",
            };
            return fetch(searchUrl, searchOptions)
              .then((response) => response.json())
              .then((searchData) => {
                return {
                  response: searchData,
                  success: true,
                };
              })
              .catch((error) => {
                return {
                  response: error,
                  success: false,
                };
              });
          }
        }
      })
      .catch((error) => {
        return {
          response: error,
          success: false,
        };
      });
  }

  public static async getJobDetails(
    hashId?: string
  ): Promise<Response<StellenAngebotDetailsInterface>> {
    const url = "https://rest.arbeitsagentur.de/oauth/gettoken_cc$";
    const options = {
      method: "POST",
      body: new URLSearchParams({
        client_id: "c003a37f-024f-462a-b36d-b001be4cd24a",
        client_secret: "32a39620-32b3-4307-9aa1-511e3d7f48a8",
        grant_type: "client_credentials",
      }),
    };
    return fetch(url, options)
      .then((response) => response.json())
      .then((tokenData: any) => {
        if (typeof URLSearchParams !== "undefined") {
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has("token") && urlParams.get("token") === "TRUE") {
          } else {
            const encodedHashID = Buffer.from(hashId).toString("base64");
            const searchUrl = `https://rest.arbeitsagentur.de/jobboerse/jobsuche-service/pc/v2/jobdetails/${encodedHashID}`;

            const searchOptions = {
              headers: {
                OAuthAccessToken: tokenData.access_token,
              },
              method: "GET",
            };
            return fetch(searchUrl, searchOptions)
              .then((response) => response.json())
              .then((searchData) => {
                return {
                  response: searchData,
                  success: true,
                };
              })
              .catch((error) => {
                return {
                  response: error,
                  success: false,
                };
              });
          }
        }
      })
      .catch((error) => {
        return {
          response: error,
          success: false,
        };
      });
  }

  private static formatQuery(query: string): string {
    try {
      const params = new URLSearchParams(query);
      const paramValues = {};

      for (const [key, value] of params.entries()) {
        paramValues[key] =
          (paramValues[key] ? `${paramValues[key]};` : "") + value;
      }

      const newParams = new URLSearchParams(paramValues);

      return decodeURIComponent(newParams.toString());
    } catch (error) {
      return "";
    }
  }
}
