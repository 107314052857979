import { StellenAngebotInterface } from "../../services/exports/Interfaces";
import moment from "moment";
import LocationIcon from "../../assets/icons/LocationIcon";

interface Props {
  job: StellenAngebotInterface;
  onClick: (item: StellenAngebotInterface) => void;
}

const JobItem = (props: Props): JSX.Element => {
  const { job, onClick } = props;
  return (
    <div
      className="mb-4 p-4 border rounded-xl cursor-pointer"
      onClick={() => onClick(job)}
      key={job?.hashId}
    >
      <div className="flex justify-between">
        <h3 className="mb-0 flex-shrink">{job?.titel}</h3>
        <p className="text-gray-500 flex-none mb-0">
          {moment(job?.aktuelleVeroeffentlichungsdatum)?.fromNow()}
        </p>
      </div>

      <p>{job?.arbeitgeber}</p>
      <div className="flex items-center">
        <LocationIcon className="h-4 w-4 mr-2" />
        <p className="text-gray-500">
          {job?.arbeitsort?.ort}, {job?.arbeitsort?.plz},{" "}
          {job?.arbeitsort?.region}, {job?.arbeitsort?.land}
        </p>
      </div>
    </div>
  );
};

export default JobItem;
