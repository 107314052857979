import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  LoginSocialApple,
  IResolveParams,
} from "reactjs-social-login";

import {
  FacebookLoginButton,
  GoogleLoginButton,
  GithubLoginButton,
  AmazonLoginButton,
  InstagramLoginButton,
  LinkedInLoginButton,
  MicrosoftLoginButton,
  TwitterLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";
import { Mail, Password } from "@mui/icons-material";
import { Button, Input, Modal } from "@nextui-org/react";
import { useCallback } from "react";
import useAuth from "../../hooks/useAuth";
import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import classnames from "classnames";

const REDIRECT_URI = process.env.REACT_APP_DOMAIN;

interface Props {
  className?: string;
  onResolve?: () => void;
}

const Login = (props: Props): JSX.Element => {
  const { className, onResolve } = props;

  const { setToken } = useAuth();

  const onLogoutSuccess = useCallback(() => {}, []);

  function _onResolve({ data }: IResolveParams): void {
    console.log({ data });
    setToken(data?.access_token);
    ReduxActions.dispatch(
      actionCreators.profile.updateProfile({
        first_name: data?.given_name,
        last_name: data?.family_name,
        email: data?.email,
      })
    );
    onResolve && onResolve();
  }

  const onLogout = useCallback(() => {}, []);

  const renderDefaultLogin = (): JSX.Element => {
    return (
      <div>
        <Input
          clearable
          bordered
          fullWidth
          color="primary"
          size="lg"
          placeholder="Email"
          contentLeft={<Mail fill="currentColor" />}
          className="mb-4"
        />
        <Input
          clearable
          bordered
          fullWidth
          color="primary"
          size="lg"
          placeholder="Password"
          contentLeft={<Password fill="currentColor" />}
          className="mb-4"
        />
        <div className="flex justify-end">
          <p className="text-sm mb-0">Forgot password?</p>
        </div>
        <div className="flex items-center mt-4">
          <Button onPress={() => console.log("")} css={{ width: "100%" }}>
            Sign in
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={classnames("w-fit h-fit pb-4", className)}>
      <Modal.Header>
        <h3>Dekrutio</h3>
      </Modal.Header>
      {/* <LoginSocialFacebook
            appId={process.env.REACT_APP_FB_APP_ID || ""}
            fieldsProfile={
              "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
            }
            onLoginStart={onLoginStart}
            onLogoutSuccess={onLogoutSuccess}
            redirect_uri={REDIRECT_URI}
            onResolve={({ provider, data }: IResolveParams) => {
              setProvider(provider);
              setProfile(data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <FacebookLoginButton />
          </LoginSocialFacebook> */}

      <LoginSocialGoogle
        client_id={process.env.REACT_APP_GG_APP_ID || ""}
        // onLoginStart={onLoginStart}
        redirect_uri={REDIRECT_URI}
        scope="openid profile email"
        discoveryDocs="claims_supported"
        access_type="offline"
        onResolve={_onResolve}
        onReject={(err) => {
          console.log(err);
        }}
      >
        <GoogleLoginButton />
      </LoginSocialGoogle>
      {/* <LoginSocialAmazon
            client_id={process.env.REACT_APP_AMAZON_APP_ID || ""}
            redirect_uri={REDIRECT_URI}
            onResolve={({ provider, data }: IResolveParams) => {
              setProvider(provider);
              setProfile(data);
            }}
            onReject={(err: any) => {
              console.log(err);
            }}
            onLoginStart={onLoginStart}
          >
            <AmazonLoginButton />
          </LoginSocialAmazon> */}
      {/* <LoginSocialInstagram
            client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || ""}
            client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || ""}
            redirect_uri={REDIRECT_URI}
            onLoginStart={onLoginStart}
            onLogoutSuccess={onLogoutSuccess}
            onResolve={({ provider, data }: IResolveParams) => {
              setProvider(provider);
              setProfile(data);
            }}
            onReject={(err: any) => {
              console.log(err);
            }}
          >
            <InstagramLoginButton />
          </LoginSocialInstagram> */}
      {/* <LoginSocialLinkedin
            client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ""}
            client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ""}
            redirect_uri={REDIRECT_URI}
            onLoginStart={onLoginStart}
            onResolve={({ provider, data }: IResolveParams) => {
              setProvider(provider);
              setProfile(data);
            }}
            onReject={(err: any) => {
              console.log(err);
            }}
          >
            <LinkedInLoginButton />
          </LoginSocialLinkedin> */}
      {/* <LoginSocialTwitter
            client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || ""}
            // client_secret={process.env.REACT_APP_TWITTER_V2_APP_SECRET || ''}
            redirect_uri={REDIRECT_URI}
            onLoginStart={onLoginStart}
            onLogoutSuccess={onLogoutSuccess}
            onResolve={({ provider, data }: IResolveParams) => {
              setProvider(provider);
              setProfile(data);
            }}
            onReject={(err: any) => {
              console.log(err);
            }}
          >
            <TwitterLoginButton />
          </LoginSocialTwitter> */}
      <div className="w-full border my-6" />
      {renderDefaultLogin()}
    </div>
  );
};

export default Login;
