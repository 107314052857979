import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProfileInterface,
} from "../../services/exports/Interfaces";

let initialState: ProfileInterface = {
  first_name: "",
  last_name: "",
  email: "",
};
export default createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (
      state: ProfileInterface,
      action: PayloadAction<ProfileInterface>
    ) => {
      try {
        state = action.payload;
      } catch (error) {}
    },
    updateProfile: (
      state: ProfileInterface,
      action: PayloadAction<Partial<ProfileInterface>>
    ) => {
      try {
        state = { ...state, ...action.payload };
      } catch (error) {}
    },
  },
});

export const props = (state: ProfileInterface) => state;
