import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "../pages/HomeScreen";
import { SCREENS } from "../services/exports/Enums";
import { RoutesInterface } from "../services/exports/Interfaces";
import useScreenValidation from "../hooks/useScreenValidation";
import NotificationsScreen from "../pages/NotificationsScreen";

function AppStack() {
  const validateScreen = useScreenValidation();

  let ROUTES: RoutesInterface[] = [
    {
      path: SCREENS.HOME_SCREEN,
      element: <HomeScreen />,
    },
    {
      path: SCREENS.NOTIFICATIONS_SCREEN,
      element: <NotificationsScreen />,
      protectedType: "Auth",
    },
  ];
  return (
    <>
      <BrowserRouter>
        <Routes>
          {ROUTES.map((route: RoutesInterface) => (
            <Route
              path={route.path}
              element={validateScreen(route)}
              key={route.path}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AppStack;
