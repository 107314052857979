import { Loading } from "@nextui-org/react";

const IntroLoadingPage = (): JSX.Element => {
  return (
    <div
      className="fixed inset-0 bg-[#DAB9A7] flex flex-col justify-center items-center"
      style={{ zIndex: 5000 }}
    >
      <Loading size="xl" color={'white'} />
      <h1 className="text-white mt-2">Decrutio</h1>
      <p className="text-white">Suchen Jobs...</p>
    </div>
  );
};

export default IntroLoadingPage;
