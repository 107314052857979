import { Input } from "@nextui-org/react";
import classnames from "classnames";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import DateTimeInput from "../inputs/DateTimeInput";
import { Textarea } from "@nextui-org/react";
import StepFooter from "./StepFooter";
import StepHeader from "./StepHeader";

interface Props {
  step: number;
  activeStep: number;
  className: string;
  onEdit: (step: number) => void;
}

const WorkExperienceStep = (props: Props): JSX.Element => {
  const { step, activeStep, className, onEdit } = props;

  const isActive: boolean = step === activeStep;

  function onSave(): void {
    onEdit(null);
  }

  function onClose(): void {
    onEdit(null);
  }

  const renderSaved = (): JSX.Element => {
    return (
      <>
        <h4>Saved</h4>
      </>
    );
  };

  const renderEdit = (): JSX.Element => {
    return (
      <>
        <Input
          label="Arbeitgeber"
          placeholder="Guillermo Rauch"
          className="mb-4"
        />
        <div className="grid grid-cols-2 gap-2 mb-4">
          <Input label="Ort" placeholder="Guillermo Rauch" />
          <Input label="Jobtitel" placeholder="Guillermo Rauch" />
        </div>
        <div className="grid grid-cols-2 gap-2 mb-4">
          <DateTimeInput label="Von" />
          <DateTimeInput label="Bis" />
        </div>
        <Textarea
          label="Meine Leistungen"
          placeholder="List deiner Aufgaben"
          className="mb-4"
        />
        <StepFooter onSave={onSave} onCancel={onClose} />
      </>
    );
  };

  return (
    <div className={classnames("flex flex-col", className)}>
      <div className="mb-4">
        <StepHeader
          icon={<WorkOutlineIcon />}
          title="Berufserfahrung"
          description="Wie können interessierte Arbeitgeber Kontakt mit dir aufnehmen?"
          onEdit={() => onEdit(step)}
        />
      </div>
      {isActive ? renderEdit() : renderSaved()}
    </div>
  );
};

export default WorkExperienceStep;
