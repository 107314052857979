import { SupportedCountriesInterface } from "./Interfaces";

export const JOB_LOCATIONS_IN_GERMANY: string[] = [
  "Berlin",
  "Hamburg",
  "München",
  "Frankfurt am Main",
  "Stuttgart",
  "Köln",
  "Leipzig",
  "Düsseldorf",
  "Nürnberg, Mittelfranken",
  "Bremen",
  "Hannover",
  "Dresden",
  "Dortmund",
  "Karlsruhe, Baden",
  "Essen, Ruhr",
  "Mannheim",
  "WIEN",
  "Augsburg, Bayern",
  "Duisburg",
  "Bielefeld",
  "Regensburg",
  "Bochum",
  "Münster, Westfalen",
  "Braunschweig",
  "Chemnitz, Sachsen",
  "Erfurt",
  "Bonn",
  "Erlangen",
  "Kiel",
  "Magdeburg",
  "Ulm, Donau",
  "Würzburg",
  "Freiburg im Breisgau",
  "Wiesbaden",
  "Mainz am Rhein",
  "Kassel, Hessen",
  "Aachen",
  "Halle (Saale)",
  "Mönchengladback",
  "Heilbronn, Neckar",
  "Ingolstadt, Donau",
  "Osnabrück",
  "Lübeck",
  "Oldenburg",
  "Rostock",
  "Wuppertal",
  "Darmstadt",
  "Potsdam",
  "Koblenz am Rhein",
  "Krefeld",
  "Heidelberg, Neckar",
  "Ludwigshafen am Rhein",
  "Jena",
  "Saarbrücken",
  "Paderborn",
  "Wolfsburg",
  "Neuss",
  "Fürth, Bayern",
  "Hagen, Westfalen",
  "Pforzheim",
  "Gelsenkirchen",
  "Bayreuth",
  "Bamberg",
  "Göttingen, Niedersachsen",
  "Reutlingen",
  "Hamm, Westfalen",
  "Ludwigsburg, Württemberg",
  "Leverkusen",
  "Oberhausen, Rheinland",
  "Schwerin, Mecklenburg",
  "Kaiserslautern",
  "Mülheim an der Ruhr",
  "Landshut, Isar",
  "Linz, Donau",
  "Hanau",
  "Aschaffenburg",
  "Bremerhaven",
  "Schweinfurt",
  "Hildesheim",
  "Kempten (Allgäu)",
  "Offenburg",
  "Trier",
  "Villingen-Schwenningen",
  "Rosenheim, Oberbayern",
  "Gütersloh",
  "Siegen",
  "Zwickau",
  "Donauwörth",
  "Norderstedt",
  "Lüneburg",
  "Esslingen am Neckar",
  "Straubing",
  "Fulda",
  "Memmingen",
  "Aalen, Württemberg",
  "Marburg",
  "Gera",
  "Friedrichshafen",
  "Salzgitter",
  "Biberach an der Riß",
];

export const JOB_TYPES_IN_GERMANY = [
  {
    type: "Informatik",
    subTypes: [
      "Softwareentwicklung",
      "Datenbanken",
      "Netzwerk-Administration",
      "Web-Entwicklung",
      "Künstliche Intelligenz (KI)",
    ],
  },
  {
    type: "Gesundheitswesen",
    subTypes: [
      "Altenpflege",
      "Krankenpflege",
      "Medizin",
      "Therapie (Physiotherapie, Ergotherapie, Logopädie)",
      "Pflegemanagement",
    ],
  },
  {
    type: "Handwerk",
    subTypes: [
      "Elektrotechnik",
      "Sanitär- und Heizungstechnik",
      "Kfz-Mechatronik",
      "Metallbau",
      "Tischlerei",
    ],
  },
  {
    type: "Ingenieurwesen",
    subTypes: [
      "Maschinenbau",
      "Elektrotechnik",
      "Bauingenieurwesen",
      "Umwelttechnik",
      "Wirtschaftsingenieurwesen",
    ],
  },
  {
    type: "Naturwissenschaften",
    subTypes: [
      "Mathematik",
      "Physik",
      "Chemie",
      "Biologie",
      "Geowissenschaften",
    ],
  },
];

export const SUPPORTED_COUNTRIES: SupportedCountriesInterface[] = [
  { languageCode: "de", languageLabel: "Deutsch", country: "Deutschland" },
  { languageCode: "en", languageLabel: "English", country: "England" },
  { languageCode: "bs", languageLabel: "Bonisch", country: "Bosnien" },
];
