import { StoreInterface } from "../../services/exports/Interfaces";

const RootReducerMiddleware = (
  state: StoreInterface | undefined,
  actions: any,
  next: (state: StoreInterface | undefined, actions: any) => void
) => {
  if (actions?.type === "RESET_STORE") {
    state = undefined;
  }

  return next(state, actions);
};

export default RootReducerMiddleware;
