import useJobSearch from "../../hooks/useJobSearch";
import JobLocationFilter from "./jobFilter/JobLocationFilter";
import JobTypeFilter from "./jobFilter/JobTypeFilter";

const JobSearchFilter = (): JSX.Element => {
  const { resetSearchDebounced } = useJobSearch();

  const section = "p-4 border-b";

  return (
    <div className="w-full border rounded-xl">
      <div className="flex justify-between items-center p-4 border-b">
        <h4 className="mb-0">Filter</h4>
        <button onClick={resetSearchDebounced}>
          <h5 className="mb-0 text-gray-500">Clear all</h5>
        </button>
      </div>
      <div className="px-1">
        <JobTypeFilter className={section} />
        <JobLocationFilter className={section} />
      </div>
    </div>
  );
};

export default JobSearchFilter;
