import useTheme from "../../hooks/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

export default function LocationIcon(props: Props) {
  const { className, color, style } = props;
  const theme = useTheme();
  return (
    <svg
      className={className ?? "h-4 w-4"}
      style={style}
      viewBox="0 0 34 42"
      fill="none"
    >
      <path
        d="M17.0008 20.5469C17.9674 20.5469 18.8008 20.1969 19.5008 19.4969C20.2008 18.7969 20.5508 17.9635 20.5508 16.9969C20.5508 16.0302 20.2008 15.1969 19.5008 14.4969C18.8008 13.7969 17.9674 13.4469 17.0008 13.4469C16.0341 13.4469 15.2008 13.7969 14.5008 14.4969C13.8008 15.1969 13.4508 16.0302 13.4508 16.9969C13.4508 17.9635 13.8008 18.7969 14.5008 19.4969C15.2008 20.1969 16.0341 20.5469 17.0008 20.5469ZM17.0008 36.7469C21.3674 32.7802 24.5924 29.1802 26.6758 25.9469C28.7591 22.7135 29.8008 19.8635 29.8008 17.3969C29.8008 13.5302 28.5674 10.3635 26.1008 7.89687C23.6341 5.43021 20.6008 4.19687 17.0008 4.19687C13.4008 4.19687 10.3674 5.43021 7.90078 7.89687C5.43411 10.3635 4.20078 13.5302 4.20078 17.3969C4.20078 19.8635 5.26745 22.7135 7.40078 25.9469C9.53411 29.1802 12.7341 32.7802 17.0008 36.7469ZM17.0008 41.2469C11.5674 36.6469 7.50911 32.3635 4.82578 28.3969C2.14245 24.4302 0.800781 20.7635 0.800781 17.3969C0.800781 12.3302 2.42578 8.29688 5.67578 5.29688C8.92578 2.29688 12.7008 0.796875 17.0008 0.796875C21.3008 0.796875 25.0758 2.29688 28.3258 5.29688C31.5758 8.29688 33.2008 12.3302 33.2008 17.3969C33.2008 20.7635 31.8591 24.4302 29.1758 28.3969C26.4924 32.3635 22.4341 36.6469 17.0008 41.2469Z"
        fill={color ?? theme.colors.gray[500]}
      />
    </svg>
  );
}
