import { useMemo, useState, useEffect, useRef } from "react";
import JobSearchContext from "../context/JobSearchContext";
import useStore from "../hooks/useStore";
import ArbeitsAgenturManager from "../services/api/ArbeitsAgenturManager";
import {
  ArbeitsAgenturSearchParamsInterface,
  PaginatedStellenAngebotInterface,
} from "../services/exports/Interfaces";
import { debounce } from "lodash";
import JobSearchResource from "../services/resources/JobSearchResource";
import IntroLoadingPage from "../components/loading/IntroLoadingPage";

interface Props {
  children: JSX.Element;
}

const JobSearchProvider = (props: Props): JSX.Element => {
  const { children } = props;

  const { query } = useStore()?.jobSearch;

  const [jobs, setJobs] = useState<PaginatedStellenAngebotInterface | null>(
    null
  );

  const [showLoadingIntro, setShowLoadingIntro] = useState<boolean>(true);

  const [searching, setSearching] = useState<boolean>(false);

  const queryRef = useRef(query);
  queryRef.current = query;

  useEffect(() => {
    initSearch();
  }, []);

  const debounceJobSearch = useRef(
    debounce(async () => {
      searchJobs();
    }, 500)
  ).current;

  async function initSearch() {
    await searchJobs();
    setShowLoadingIntro(false);
  }

  async function searchJobs(
    payload?: Partial<ArbeitsAgenturSearchParamsInterface>
  ) {
    setSearching(true);
    const request = await ArbeitsAgenturManager.findJobs(
      payload ? JobSearchResource.createSearch(payload) : queryRef.current
    );
    const { response, success } = request;
    setSearching(false);
    if (success) {
      setJobs(response);
      JobSearchResource.setSearch(payload);
    }
  }

  function setSearchDebounced(
    payload: Partial<ArbeitsAgenturSearchParamsInterface>
  ): void {
    new Promise((resolve) => {
      JobSearchResource.setSearch(payload);
      resolve(true);
    }).then(debounceJobSearch);
  }

  function deleteSearchDebounced(
    payload: Partial<ArbeitsAgenturSearchParamsInterface>
  ): void {
    new Promise((resolve) => {
      JobSearchResource.deleteSearch(payload);
      resolve(true);
    }).then(debounceJobSearch);
  }

  function resetSearchDebounced(): void {
    new Promise((resolve) => {
      JobSearchResource.resetSearch();
      resolve(true);
    }).then(debounceJobSearch);
  }

  const context = useMemo(
    () => ({
      jobs,
      searching,
      searchParams: new URLSearchParams(query),
      showLoadingIntro,
      setJobs,
      setSearching,
      searchJobs,
      debounceJobSearch,
      setSearchDebounced,
      deleteSearchDebounced,
      resetSearchDebounced,
    }),
    [jobs, searching, query, showLoadingIntro]
  );

  return (
    <JobSearchContext.Provider value={context}>
      {children}
      {showLoadingIntro && <IntroLoadingPage />}
    </JobSearchContext.Provider>
  );
};

export default JobSearchProvider;
