import { useState } from "react";
import { Modal } from "@nextui-org/react";
import useGlobalModals from "../../hooks/useGlobalModals";
import EducationStep from "../resume/EducationStep";
import WorkExperienceStep from "../resume/WorkExperienceStep";
import PersonalInfoStep from "../resume/PersonalInfoStep";
import LanguageStep from "../resume/LanguageStep";

export interface ResumeModalProps {
  openModal: boolean;
  onSuccess?: () => void;
}

const ResumeModal = (): JSX.Element => {
  const { resumeModalProps, setResumeModalProps } = useGlobalModals();

  const [activeStep, setActiveStep] = useState<number | null>(null);

  function onClose(): void {
    setResumeModalProps({ openModal: false });
  }

  function onAccept(): void {
    onClose();
    resumeModalProps?.onSuccess && resumeModalProps.onSuccess();
  }

  return (
    <Modal
      scroll
      fullScreen
      closeButton
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={resumeModalProps?.openModal}
      onClose={onClose}
    >
      <Modal.Header>
        <h3>Lebenslauf</h3>
      </Modal.Header>
      <Modal.Body className="w-1/2 mx-auto">
        <PersonalInfoStep
          className={section}
          step={1}
          activeStep={activeStep}
          onEdit={setActiveStep}
        />
        <WorkExperienceStep
          className={section}
          step={2}
          activeStep={activeStep}
          onEdit={setActiveStep}
        />
        <EducationStep
          className={section}
          step={3}
          activeStep={activeStep}
          onEdit={setActiveStep}
        />
        <LanguageStep
          className={section}
          step={4}
          activeStep={activeStep}
          onEdit={setActiveStep}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ResumeModal;

const section = "border-b border-b-2 py-6";
