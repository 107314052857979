import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Dropdown, Text } from "@nextui-org/react";
import useGlobalModals from "../../hooks/useGlobalModals";
import useStore from "../../hooks/useStore";
import useAuth from "../../hooks/useAuth";
import ReduxActions from "../../store/ReduxActions";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../services/exports/Enums";

const MenuDropDown = (): JSX.Element => {
  const navigate = useNavigate();

  const { setResumeModalProps, setLoginModalProps } = useGlobalModals();

  const { isLoggedIn, deleteToken } = useAuth();

  const { email } = useStore()?.profile;

  function onLogout(): void {
    deleteToken();
    ReduxActions.clearReduxStore();
  }

  return (
    <Dropdown placement="bottom-left">
      <Dropdown.Trigger>
        <div
          className={
            "h-10 aspect-square flex justify-center items-center bg-gray-100 rounded-full mr-4 relative"
          }
        >
          <MenuOutlinedIcon />
          <div className="h-4 aspect-square rounded-full bg-red-500 absolute top-1 right-0 border border-white animate-ping" />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Menu color="secondary" aria-label="Avatar Actions">
        <Dropdown.Item key="profile" className="py-4">
          {isLoggedIn ? (
            <>
              <Text b color="inherit" css={{ d: "flex" }}>
                Eingeloggt
              </Text>
              {email && <p>{email}</p>}
            </>
          ) : (
            <button onClick={() => setLoginModalProps({ openModal: true })}>
              <Text b color="inherit" css={{ d: "flex" }}>
                Einloggen
              </Text>
            </button>
          )}
        </Dropdown.Item>
        <Dropdown.Item key="settings" withDivider>
          <button onClick={() => setResumeModalProps({ openModal: true })}>
            Lebenslauf
          </button>
        </Dropdown.Item>
        <Dropdown.Item key="team_settings">
          <button
            className="flex justify-between items-center w-full"
            onClick={() => navigate(SCREENS.NOTIFICATIONS_SCREEN)}
          >
            <p>Benachrichtigung</p>
            <div className="h-6 aspect-square rounded-full flex justify-center items-center bg-red-500">
              <p className="text-white">2</p>
            </div>
          </button>
        </Dropdown.Item>
        <Dropdown.Item key="logout" color="error" withDivider>
          <button onClick={onLogout}>Log Out</button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MenuDropDown;
