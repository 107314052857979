import { useContext } from "react";
import { Translate } from "react-auto-translate";
import TranslatorContext from "../context/TranslatorContext";

export default function useTranslator() {
  const translatorContext = useContext(TranslatorContext);
  return {
    T: Translate,
    ...translatorContext,
  };
}
