import { actionCreators } from "../../store/actions";
import ReduxActions from "../../store/ReduxActions";
import { ArbeitsAgenturSearchParamsInterface } from "../exports/Interfaces";
import collect from "collect.js";
import { JOB_FILTER_KEYS } from "../exports/Enums";

export default class JobSearchResource {
  public static createSearch(
    payload: Partial<ArbeitsAgenturSearchParamsInterface>
  ): string {
    try {
      let newPayload: Partial<ArbeitsAgenturSearchParamsInterface> = {
        page: 1,
        ...payload,
      };

      let searchParams = new URLSearchParams(JobSearchResource.getQuery());

      for (var key in newPayload) {
        switch (key) {
          case JOB_FILTER_KEYS.JOB_HOURS:
          case JOB_FILTER_KEYS.JOB_LOCATION:
          case JOB_FILTER_KEYS.JOB_LIMIT:
          case JOB_FILTER_KEYS.JOB_TYPE:
            searchParams.append(key, newPayload[key]?.toString());
            break;
          default:
            searchParams.set(key, newPayload[key]?.toString());
            break;
        }
      }

      return searchParams?.toString();
    } catch (error) {
      return "";
    }
  }

  public static setSearch(
    payload: Partial<ArbeitsAgenturSearchParamsInterface>
  ): void {
    try {
      ReduxActions.dispatch(
        actionCreators.jobSearch.setSearch(
          JobSearchResource.createSearch(payload)
        )
      );
    } catch (error) {}
  }

  public static deleteSearch(
    payload: Partial<ArbeitsAgenturSearchParamsInterface>
  ): void {
    try {
      let searchParams = new URLSearchParams(JobSearchResource.getQuery());

      for (var key in payload) {
        if (payload[key]) {
          searchParams = this.deleteParamsEntry(
            searchParams,
            key,
            payload[key]
          );
        } else {
          searchParams.delete(key);
        }
      }

      ReduxActions.dispatch(
        actionCreators.jobSearch.setSearch(searchParams?.toString())
      );
    } catch (error) {}
  }

  private static deleteParamsEntry(
    params: URLSearchParams,
    key: string,
    value: string
  ): URLSearchParams {
    const newEntries = Array.from(params.entries()).filter(
      ([k, v]) => !(k === key && v === value)
    );
    return new URLSearchParams(newEntries);
  }

  public static resetSearch(): void {
    ReduxActions.dispatch(actionCreators.jobSearch.setSearch(""));
  }

  public static getSearchParamByKeyId(
    searchParams: URLSearchParams,
    key: string,
    id: string
  ): string {
    try {
      return collect(searchParams.getAll(key))?.first((value) => value === id);
    } catch (error) {
      return "";
    }
  }

  public static getAllSearchParamByKeyId(
    searchParams: URLSearchParams,
    key: string
  ): string[] {
    try {
      return searchParams.getAll(key);
    } catch (error) {
      return [];
    }
  }

  public static getQuery() {
    return ReduxActions.getStore()?.jobSearch?.query;
  }
}
