import React from "react";

interface Props {
  illustration: any;
  title: string;
  description: string;
}

export default function IllustrationExplainer(props: Props) {
  const { illustration, title, description } = props;
  return (
    <div className={"flex flex-col items-center"}>
      <div className="mb-small">{illustration}</div>

      <h5 className={"text-center"}>{title}</h5>
      <p className={"text-center text-sm text-gray-500"}>{description}</p>
    </div>
  );
}
