import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

interface Props {
  icon: any;
  title: string;
  description: string;
  onEdit: () => void;
}

const StepHeader = (props: Props): JSX.Element => {
  const { icon, title, description, onEdit } = props;
  return (
    <button className="flex justify-between cursor-pointer w-full" onClick={onEdit}>
      <div>
        <div className="flex items-center">
          {icon}
          <h4 className="mb-0 ml-2">{title}</h4>
        </div>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <ModeEditOutlinedIcon />
    </button>
  );
};

export default StepHeader;
