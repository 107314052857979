import { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import {
  StellenAngebotDetailsInterface,
  StellenAngebotInterface,
} from "../../services/exports/Interfaces";
import ArbeitsAgenturManager from "../../services/api/ArbeitsAgenturManager";
import { Loading } from "@nextui-org/react";
import DateTimeResource from "../../services/resources/DateTimeResource";
import useAuth from "../../hooks/useAuth";
import useGlobalModals from "../../hooks/useGlobalModals";
import ApplicationSent from "../animations/ApplicationSent";

interface Props {
  job: StellenAngebotInterface;
  contentWidth: number;
}

const JobDetails = (props: Props): JSX.Element => {
  const { job, contentWidth } = props;

  const { setLoginModalProps, setResumeModalProps } = useGlobalModals();

  const { isLoggedIn } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);

  const [jobDetails, setJobDetails] =
    useState<StellenAngebotDetailsInterface | null>(null);

  const [showApplicationSent, setShowApplicationSent] =
    useState<boolean>(false);

  useEffect(() => {
    setTimeout(getJobDetails, 500);
  }, []);

  async function getJobDetails() {
    const { response, success } = await ArbeitsAgenturManager.getJobDetails(
      job?.hashId
    );
    setLoading(false);
    if (success) {
      setJobDetails(response);
    }
  }

  function onApply(): void {
    if (isLoggedIn) {
      return showResume();
    }
    setLoginModalProps({ openModal: true, onLoginSucceed: showResume });
  }

  function showResume(): void {
    setResumeModalProps({ openModal: true, onSuccess: sendApplication });
  }

  function sendApplication() {
    setShowApplicationSent(true);
  }

  function onAnimationEnd(): void {
    setTimeout(() => {
      setShowApplicationSent(false);
    }, 500);
  }

  return (
    <>
      <div className="w-full h-full relative">
        <div className="p-4">
          <div className="flex justify-between items-center">
            <p>{job?.beruf}</p>
            <p>
              Veröffentlicht:{" "}
              {DateTimeResource.formatDate(
                jobDetails?.aktuelleVeroeffentlichungsdatum
              )}
            </p>
          </div>

          <h3>{job?.titel}</h3>
          <p>{job?.arbeitgeber}</p>
        </div>
        <div className="p-4">
          <h4>Stellenbeschreibung</h4>
          <p className="whitespace-pre-line">
            {jobDetails?.stellenbeschreibung}
          </p>
        </div>
        <div className="p-4">
          <h4>Führerscheine</h4>
        </div>
        {loading && (
          <div className="w-full flex flex-col justify-center items-center">
            <Loading />
          </div>
        )}

        <div
          className="fixed bottom-0 w-full px-4 py-2 border-t bg-white"
          style={{ width: contentWidth }}
        >
          <Button css={{ width: "100%" }} onPress={onApply}>
            Jetzt bewerben
          </Button>
        </div>
      </div>
      {showApplicationSent && (
        <div className="h-full fixed top-0" style={{ width: contentWidth }}>
          <ApplicationSent onAnimationEnd={onAnimationEnd} />
        </div>
      )}
    </>
  );
};

export default JobDetails;
