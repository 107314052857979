import { useState, useMemo } from "react";
import { Translator } from "react-auto-translate";
import TranslatorContext from "../context/TranslatorContext";
import { SUPPORTED_COUNTRIES } from "../services/exports/Constants";
import { CacheProvider } from "../services/exports/Types";

interface Props {
  children: JSX.Element;
}

const TranslatorProvider = (props: Props): JSX.Element => {
  const { children } = props;

  const defaultLanguage = "de";

  const [language, setLanguage] = useState(
    SUPPORTED_COUNTRIES[2]?.languageCode
  );

  const cacheProvider: CacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };

  const context = useMemo(
    () => ({
      language,
      setLanguage,
    }),
    [language]
  );

  return (
    <Translator
      cacheProvider={cacheProvider}
      from={defaultLanguage}
      to={language}
      googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      <TranslatorContext.Provider value={context}>
        {children}
      </TranslatorContext.Provider>
    </Translator>
  );
};

export default TranslatorProvider;
