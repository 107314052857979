import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobSearchStoreInterface } from "../../services/exports/Interfaces";

let initialState: JobSearchStoreInterface = {
  query: "",
};
export default createSlice({
  name: "jobSearch",
  initialState,
  reducers: {
    setSearch: (
      state: JobSearchStoreInterface,
      action: PayloadAction<string>
    ) => {
      try {
        state.query = action.payload;
      } catch (error) {}
    },
  },
});

export const props = (state: JobSearchStoreInterface) => state;
