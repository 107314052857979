import moment from "moment";

export default class DateTimeResource {
  public static formatDate(date: any, format?: string) {
    try {
      return moment(date).format(format ?? "MM.DD.YYYY");
    } catch (error) {
      return "";
    }
  }
}
