import { Dispatch, MiddlewareAPI } from "redux";

const InterceptorMiddleware =
  (middleware: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
    // Here you have access to `action.type` and `action.payload`
    // You should always do this at the end of your middleware

    return next(action);
  };

export default InterceptorMiddleware;
