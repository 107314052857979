//@ts-ignore
import { createActions } from "redux-actions";
import { ActionCreatorInterface } from "../services/exports/Interfaces";

export const actionCreators: ActionCreatorInterface = createActions({
  jobSearch: {
    setSearch: undefined,
  },
  profile: {
    setProfile: undefined,
    updateProfile: undefined,
  },
  appStatus: {
    setHasSeenAppExplainer: undefined,
  },
});
