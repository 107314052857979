import LoginScreen from "../../pages/LoginScreen";
import useAuth from "../../hooks/useAuth";

interface Props {
  children: React.ReactNode;
}

const AuthRoute = (props: Props): any => {
  const { children } = props;

  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <LoginScreen />;
  }

  return children;
};

export default AuthRoute;
