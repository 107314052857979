import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import JobSearchSlice from "./slices/JobSearchSlice";
import InterceptorMiddleware from "./middleware/InterceptorMiddleware";
import RootReducerMiddleware from "./middleware/RootReducerMiddleware";
import ProfileSlice from "./slices/ProfileSlice";
import AppStatusSlice from "./slices/AppStatusSlice";

let rehydrationComplete: any;
let rehydrationFailed: any;

const rehydrationPromise = new Promise((resolve, reject) => {
  rehydrationComplete = resolve;
  rehydrationFailed = reject;
});

export function rehydration() {
  return rehydrationPromise;
}

const persistConfig = {
  key: "root",
  storage,
};

export const reducersCombined = combineReducers({
  jobSearch: JobSearchSlice.reducer,
  profile: ProfileSlice.reducer,
  appStatus: AppStatusSlice.reducer,
});

const reducerPersisted = persistReducer(persistConfig, reducersCombined);

const configure = () => {
  let store = configureStore({
    reducer: (state, actions) =>
      RootReducerMiddleware(state, actions, reducerPersisted),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(InterceptorMiddleware),
  });

  return store;
};

export const store = configure();
export const persistor = persistStore(store, null, () => {
  // this will be invoked after rehydration is complete
  rehydrationComplete();
});
