import { Input } from "@nextui-org/react";
import classnames from "classnames";
import StepHeader from "./StepHeader";
import { Radio } from "@nextui-org/react";
import StepFooter from "./StepFooter";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";

interface Props {
  step: number;
  activeStep: number;
  className: string;
  onEdit: (step: number) => void;
}

const LanguageStep = (props: Props): JSX.Element => {
  const { step, activeStep, className, onEdit } = props;

  const isActive: boolean = step === activeStep;

  function onSave(): void {
    onEdit(null);
  }

  function onClose(): void {
    onEdit(null);
  }

  const renderSaved = (): JSX.Element => {
    return (
      <>
        <h4>Saved</h4>
      </>
    );
  };

  const renderEdit = (): JSX.Element => {
    return (
      <>
        <Input label="Sprache" placeholder="Guillermo Rauch" className="mb-4" />
        <div className="mb-4">
          <Radio.Group label="Sprachlevel" defaultValue="A">
            <Radio value="A">Grundkenntnisse</Radio>
            <Radio value="B">Gute Kenntnisse</Radio>
            <Radio value="C">Sehr gute Kenntnisse</Radio>
            <Radio value="D">Verhandlungssicher</Radio>
            <Radio value="E">Muttersprache</Radio>
          </Radio.Group>
        </div>

        <StepFooter onSave={onSave} onCancel={onClose} />
      </>
    );
  };

  return (
    <div className={classnames("flex flex-col", className)}>
      <div className="mb-4">
        <StepHeader
          icon={<PublicOutlinedIcon />}
          title="Sprachen"
          description="Wie können interessierte Arbeitgeber Kontakt mit dir aufnehmen?"
          onEdit={() => onEdit(step)}
        />
      </div>
      {isActive ? renderEdit() : renderSaved()}
    </div>
  );
};

export default LanguageStep;
