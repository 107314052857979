import React from "react";
import AuthRoute from "../components/navigation/AuthRoute";
import { RoutesInterface } from "../services/exports/Interfaces";

export default function useScreenValidation() {
  function render(route: RoutesInterface): React.ReactNode {
    switch (route?.protectedType) {
      case "Auth":
        return <AuthRoute>{route.element}</AuthRoute>;
      default:
        return route?.element;
    }
  }

  return render;
}
