import Select from "react-select";
import useTranslator from "../../hooks/useTranslator";
import collect from "collect.js";
import { SUPPORTED_COUNTRIES } from "../../services/exports/Constants";
import { SupportedCountriesInterface } from "../../services/exports/Interfaces";

const CountrySelector = (): JSX.Element => {
  const { language, setLanguage } = useTranslator();

  const defaultOption = collect(SUPPORTED_COUNTRIES)?.firstWhere(
    "languageCode",
    language
  );

  function changeHandler(data: SupportedCountriesInterface): void {
    setLanguage(data?.languageCode);
  }

  const Option = (props: any): JSX.Element => {
    const { innerProps, innerRef, data } = props;
    return (
      <div className="p-2" ref={innerRef} {...innerProps}>
        {data?.country}
      </div>
    );
  };

  const OptionLabel = (data: SupportedCountriesInterface): React.ReactNode => (
    <div>
      <p>{data?.country}</p>
    </div>
  );

  return (
    <Select
      options={SUPPORTED_COUNTRIES}
      defaultValue={defaultOption}
      onChange={changeHandler}
      components={{ Option }}
      formatOptionLabel={OptionLabel}
    />
  );
};

export default CountrySelector;
