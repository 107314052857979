import { useState, useRef } from "react";
import { Button, Input, Loading } from "@nextui-org/react";
import useJobSearch from "../../hooks/useJobSearch";
import MenuDropDown from "../navigation/MenuDropDown";

const JobSearch = (): JSX.Element => {
  const { searching, searchParams, searchJobs, setSearchDebounced } =
    useJobSearch();

  const [what, setWhat] = useState<string>(searchParams.get("was"));

  const [where, setWhere] = useState<string>(searchParams.get("wo"));

  const containerRef = useRef<HTMLDivElement>(null);

  function search(): void {
    let data = {};
    if (what) {
      data["was"] = what;
    }
    if (where) {
      data["wo"] = where;
    }
    searchJobs(data);
  }

  function scrollToTop(): void {
    containerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  return (
    <div
      className="flex items-center border-b shadow bg-white px-4 items-center"
      ref={containerRef}
    >
      <MenuDropDown />
      <h4 className="mb-0">DECRUTIO</h4>
      <div className="p-4 border-r w-full">
        <Input
          placeholder="Beruf, Stichword, Referenz"
          fullWidth
          clearable
          value={what}
          onChange={(e) => setWhat(e.target.value)}
          onClearClick={() => setSearchDebounced({ was: "" })}
          onFocus={scrollToTop}
        />
      </div>
      <div className="p-4 w-full">
        <Input
          placeholder="Ort, PLZ, Bundesland"
          fullWidth
          clearable
          value={where}
          onChange={(e) => setWhere(e.target.value)}
          onClearClick={() => setSearchDebounced({ wo: "" })}
          onFocus={scrollToTop}
        />
      </div>
      <div className="py-4">
        <Button onPress={search}>
          {searching ? <Loading color="white" size="sm" /> : "Suchen"}
        </Button>
      </div>
    </div>
  );
};

export default JobSearch;
