interface Props {
  chat: any;
}

const ChatHeader = (props: Props) => {
  const { chat } = props;

  return (
    <div className="bg-white flex justify-center items-center p-4 border-b sticky top-0 z-50">
      <h3 className="mb-0">{chat?.title}</h3>
    </div>
  );
};

export default ChatHeader;
