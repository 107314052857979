export enum SCREENS {
  HOME_SCREEN = "/",
  NOTIFICATIONS_SCREEN = "/notifications",
  ABOUT_US = "/about-us",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_AND_CONDITIONS = "/terms-conditions",
  IMPRESSUM = "/impressum",
}

export enum JOB_FILTER_KEYS {
  JOB_HOURS = "arbeitszeit",
  JOB_LIMIT = "befristung",
  JOB_LOCATION = "wo",
  JOB_TYPE = "was",
}
