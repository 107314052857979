import { SCREENS } from "../../services/exports/Enums";
import { useNavigate } from "react-router-dom";
const PageFooter = (): JSX.Element => {
  const navigate = useNavigate();

  const links = [
    {
      pathname: SCREENS.PRIVACY_POLICY,
      title: "Datenschutz",
    },
    {
      pathname: SCREENS.TERMS_AND_CONDITIONS,
      title: "Geschäftsbedingungen",
    },
    {
      pathname: SCREENS.IMPRESSUM,
      title: "Impressum",
    },
  ];
  return (
    <div className="w-full p-4 bg-gray-100">
      <div className="flex items-center justify-center">
        {links?.map((link) => (
          <button onClick={() => navigate(link?.pathname)}>
            <h6 className="mr-6 mb-0 hover:text-blue-500">{link?.title}</h6>
          </button>
        ))}
      </div>
      <div className="mt-4">
        <p className="text-center text-sm">© 2023 Decrutio UG. All Rechte reserviert</p>
      </div>
    </div>
  );
};

export default PageFooter;
