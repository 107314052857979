import DatePicker from "react-datepicker";
import DateRangeIcon from "@mui/icons-material/DateRange";

interface Props {
  label: string;
}

const DateTimeInput = (props: Props): JSX.Element => {
  const { label } = props;
  return (
    <div>
      <p className="text-sm mb-2">{label}</p>
      <div className="flex items-center p-2 border rounded-xl bg-gray-100">
        <DateRangeIcon className="mr-2" />
        <DatePicker
          // selected={startDate}
          // onChange={(date) => setStartDate(date)}
          className="bg-gray-100 w-full"
        />
      </div>
    </div>
  );
};

export default DateTimeInput;
