import Lottie from "react-lottie-player";
import { CheckmarkAnimation } from "../../assets/animations";

interface Props {
  onAnimationEnd?: () => void;
}

const ApplicationSent = (props: Props): JSX.Element => {
  const { onAnimationEnd } = props;

  return (
    <div className="w-full h-full bg-white flex flex-col justify-center items-center">
      <Lottie
        animationData={CheckmarkAnimation}
        loop={false}
        play
        style={{ height: window.innerHeight * 0.2 }}
        onAnimationEnd={() => onAnimationEnd && onAnimationEnd()}
      />
      <h3>Bewerbung verschickt</h3>
    </div>
  );
};

export default ApplicationSent;
