import Login from "../components/auth/Login";

const LoginScreen = (): JSX.Element => {
  return (
    <div className="w-screen h-screen bg-[#DAB9A7] flex flex-col justify-center items-center">
      <Login className="bg-white p-4 rounded-xl" />
    </div>
  );
};

export default LoginScreen;
