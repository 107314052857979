import { useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import { motion } from "framer-motion";
import { Button } from "@nextui-org/react";
import ReduxActions from "../../store/ReduxActions";
import { actionCreators } from "../../store/actions";
import useStore from "../../hooks/useStore";
import useJobSearch from "../../hooks/useJobSearch";

export interface AppExplainerModalProps {
  openModal: boolean;
}

const AppExplainer = (): JSX.Element => {
  const { hasSeenAppExplainer } = useStore()?.appStatus;

  const { showLoadingIntro } = useJobSearch();

  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return !hasSeenAppExplainer && !showLoadingIntro && mounted ? (
    <Intro />
  ) : null;
};

const Intro = (): JSX.Element => {
  const [index, setIndex] = useState<number>(0);

  const [animateOut, setAnimateOut] = useState<boolean>(false);

  let steps = [
    {
      title: "Mehrsprachige Bewerbungen leicht gemacht 🌍💻👨‍💼",
      description:
        "Bewerben Sie sich in Ihrer Muttersprache über unsere Plattform.",
    },
    {
      title: "Automatische Übersetzung Ihrer Bewerbung 📝🔍🌐",
      description: "Wir übersetzen automatisch Ihre Bewerbung ins Deutsche.",
    },
    {
      title: "Schnelle Rückmeldung auf Ihre Bewerbung ⏰📨👍",
      description:
        "Innerhalb einer Woche erhalten Sie eine Rückmeldung von uns.",
    },
  ];

  const isLastStep = index === steps?.length - 1;

  function onClose(): void {
    ReduxActions.dispatch(
      actionCreators.appStatus.setHasSeenAppExplainer(true)
    );
  }

  function moveForward(): void {
    if (isLastStep) {
      return setAnimateOut(true);
    }
    setIndex(index + 1);
  }

  return (
    <motion.div
      className="fixed inset-0 bg-[#DAB9A7] flex flex-col justify-center items-center"
      style={{ zIndex: 1000 }}
      animate={{ opacity: animateOut ? 0 : 1, x: 0 }}
      onAnimationEnd={() => animateOut && onClose()}
    >
      <div className="w-1/2 mx-auto">
        <div className="flex flex-col py-4">
          <StepAnimation step={steps[index]} index={index} />
        </div>
        <div className="flex justify-end items-center">
          <Button auto onPress={moveForward}>
            {isLastStep ? "Jetzt loslegen" : "Weiter"}
            {isLastStep ? <CheckIcon className="ml-2" /> : <ArrowForwardIcon />}
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

const StepAnimation = ({ step, index }) => {
  return (
    <motion.div
      key={index?.toString()}
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      className="mb-4"
      whileHover={{ scale: 1.03 }}
    >
      <h1 className="">{step?.title}</h1>
      <p className="text-2xl">{step?.description}</p>
    </motion.div>
  );
};

export default AppExplainer;
