import { rehydration, store } from "./index";
import { batch } from "react-redux";
import { StoreInterface } from "../services/exports/Interfaces";

export default class ReduxActions {
  /**
   * Dispatch an action to redux reducers so that they update the state
   * @dispatch dispatch
   */
  public static dispatch = (action): void => {
    store.dispatch(action);
  };

  /**
   * Return the current state of the store
   * @dispatch getStore
   */
  public static getStore = (): StoreInterface => {
    return store.getState();
  };

  /**
   * Clear redux store
   * @method clearReduxStore
   */
  public static clearReduxStore = (): void => {
    store.dispatch({ type: "RESET_STORE" });
  };

  /**
   * Dispatch multiple actions and cause a single rerender
   * @method batchActions
   */
  public static batchActions = (actionArray: any[]): void => {
    return batch(() => {
      actionArray.forEach((action) => {
        store.dispatch(action);
      });
    });
  };

  /**
   * Wait until Redux store is hydrated
   * @method rehydrated
   */
  public static rehydrated = (): Promise<unknown> => {
    return rehydration();
  };
}
