import { Input } from "@nextui-org/react";
import classnames from "classnames";
import StepHeader from "./StepHeader";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import DateTimeInput from "../inputs/DateTimeInput";
import StepFooter from "./StepFooter";

interface Props {
  step: number;
  activeStep: number;
  className: string;
  onEdit: (step: number) => void;
}

const EducationStep = (props: Props): JSX.Element => {
  const { step, activeStep, className, onEdit } = props;

  const isActive: boolean = step === activeStep;

  function onSave(): void {
    onEdit(null);
  }

  function onClose(): void {
    onEdit(null);
  }

  const renderSaved = (): JSX.Element => {
    return (
      <>
        <h4>Saved</h4>
      </>
    );
  };

  const renderEdit = (): JSX.Element => {
    return (
      <>
        <Input
          label="Abschluss/Lehrberuf"
          placeholder="Guillermo Rauch"
          className="mb-4"
        />
        <Input
          label="Bildungseinrichtung"
          placeholder="Guillermo Rauch"
          className="mb-4"
        />
        <Input label="Ort" placeholder="Guillermo Rauch" className="mb-4" />
        <Input
          label="Fachrichtung"
          placeholder="Guillermo Rauch"
          className="mb-4"
        />
        <div className="mb-4">
          <DateTimeInput label="Abschlussjahr" />
        </div>

        <StepFooter onSave={onSave} onCancel={onClose} />
      </>
    );
  };

  return (
    <div className={classnames("flex flex-col", className)}>
      <div className="mb-4">
        <StepHeader
          icon={<SchoolOutlinedIcon />}
          title="Bildungsweg"
          description="Wie können interessierte Arbeitgeber Kontakt mit dir aufnehmen?"
          onEdit={() => onEdit(step)}
        />
      </div>
      {isActive ? renderEdit() : renderSaved()}
    </div>
  );
};

export default EducationStep;
