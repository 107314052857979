import { useMemo, useState } from "react";
import LoginModal, { LoginModalProps } from "../components/modals/LoginModal";
import ResumeModal, {
  ResumeModalProps,
} from "../components/modals/ResumeModal";
import GlobalModalsContext from "../context/GlobalModalsContext";

interface Props {
  children: JSX.Element;
}

const GlobalModalsProvider = (props: Props): JSX.Element => {
  const { children } = props;

  const [loginModalProps, setLoginModalProps] = useState<LoginModalProps>();

  const [resumeModalProps, setResumeModalProps] = useState<ResumeModalProps>();

  const context = useMemo(
    () => ({
      loginModalProps,
      resumeModalProps,
      setLoginModalProps,
      setResumeModalProps,
    }),
    [loginModalProps, resumeModalProps]
  );

  const renderLoginModal = useMemo(() => {
    return <LoginModal />;
  }, [loginModalProps]);

  const renderResumeModal = useMemo(() => {
    return <ResumeModal />;
  }, [resumeModalProps]);

  return (
    <GlobalModalsContext.Provider value={context}>
      {children}
      {renderResumeModal}
      {renderLoginModal}
    </GlobalModalsContext.Provider>
  );
};

export default GlobalModalsProvider;
