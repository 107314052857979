import collect from "collect.js";
import { JOB_LOCATIONS_IN_GERMANY } from "../../../services/exports/Constants";
import Select, { ActionMeta, MultiValue } from "react-select";
import useJobSearch from "../../../hooks/useJobSearch";
import JobSearchResource from "../../../services/resources/JobSearchResource";
import { JOB_FILTER_KEYS } from "../../../services/exports/Enums";

interface Props {
  className?: string;
}

const JobLocationFilter = (props: Props): JSX.Element => {
  const { className } = props;

  const { searchParams, setSearchDebounced, deleteSearchDebounced } =
    useJobSearch();

  const searchKey: string = JOB_FILTER_KEYS.JOB_LOCATION;

  interface OptionInterface {
    label: string;
    value: string;
  }

  const options: OptionInterface[] = collect(JOB_LOCATIONS_IN_GERMANY)
    ?.map((location) => {
      return {
        label: location,
        value: location,
      };
    })
    ?.toArray();

  function getValue(): OptionInterface[] {
    try {
      return options.filter((obj) =>
        JobSearchResource.getAllSearchParamByKeyId(
          searchParams,
          searchKey
        ).includes(obj.value)
      );
    } catch (error) {
      return [];
    }
  }

  function onChange(
    newValue: MultiValue<OptionInterface>,
    actionMeta: ActionMeta<OptionInterface>
  ): void {
    if (actionMeta.option?.value) {
      return setSearchDebounced({ wo: actionMeta.option?.value });
    }
    deleteSearchDebounced({ wo: actionMeta.removedValue?.value });
  }

  return (
    <div className={className}>
      <h4>Arbeitsort</h4>
      <div>
        <Select
          isMulti
          name="colors"
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          defaultValue={getValue()}
          value={getValue()}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
export default JobLocationFilter;
