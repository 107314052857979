import { Button } from "@nextui-org/react";

interface Props {
  onSave: () => void;
  onCancel: () => void;
}

const StepFooter = (props: Props): JSX.Element => {
  const { onSave, onCancel } = props;
  return (
    <div>
      <div className="flex">
        <Button onPress={onSave} className="mr-2">
          Speichern
        </Button>
        <Button flat auto color="error" onPress={onCancel}>
          Abbrechen
        </Button>
      </div>
    </div>
  );
};

export default StepFooter;
