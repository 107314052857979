import useTheme from "../../hooks/useTheme";

interface Props {
  className?: string;
  style?: Object;
  color?: string;
}

const SearchIllustration = (props: Props): JSX.Element => {
  const { className, color, style } = props;
  const theme = useTheme();
  return (
    <svg
      className={className ?? "lg:h-40 lg:w-40 md:w-36 md:h-36 w-24 h-24"}
      viewBox="0 0 309 309"
      fill="none"
      style={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M266.983 140.992C266.983 210.577 210.576 266.984 140.992 266.984C71.407 266.984 15 210.577 15 140.992C15 71.407 71.407 15 140.992 15C210.576 15 266.983 71.407 266.983 140.992Z"
        stroke={color ?? theme.colors.gray[500]}
        stroke-width="28.062"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M294.473 294.474L240.64 240.641"
        stroke={color ?? theme.colors.gray[500]}
        stroke-width="28.062"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SearchIllustration;
