import { useEffect, useState } from "react";

export default function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [scrolling, setScrolling] = useState<boolean>(false);

  let removed = false;
  let handle = null;

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);

      if (handle) {
        clearTimeout(handle);
        setScrolling(true);
      }

      handle = setTimeout(() => setScrolling(false), 100);
    };

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => {
      if (removed) {
        return;
      }

      removed = true;
      if (handle) {
        clearTimeout(handle);
      }

      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  return { scrollPosition, scrolling };
}
