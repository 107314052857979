import { Checkbox } from "@nextui-org/react";
import useJobSearch from "../../../hooks/useJobSearch";
import { JOB_TYPES_IN_GERMANY } from "../../../services/exports/Constants";
import { JOB_FILTER_KEYS } from "../../../services/exports/Enums";
import JobSearchResource from "../../../services/resources/JobSearchResource";

interface Props {
  className?: string;
}

const JobTypeFilter = (props: Props): JSX.Element => {
  const { className } = props;

  const { searchParams, setSearchDebounced, deleteSearchDebounced } =
    useJobSearch();

  const searchKey: string = JOB_FILTER_KEYS.JOB_TYPE;

  return (
    <div className={className}>
      <h4>Fachbereich</h4>
      <div className="flex flex-col">
        <div className="border-b mb-2 pb-2">
          <Checkbox
            defaultSelected={!searchParams?.has(searchKey)}
            value={""}
            isSelected={!searchParams?.has(searchKey)}
            onChange={(isSelected) => {
              if (isSelected) {
                deleteSearchDebounced({ was: undefined });
              }
            }}
            css={{ zIndex: 0 }}
          >
            Alle anzeigen
          </Checkbox>
        </div>

        {JOB_TYPES_IN_GERMANY?.map((jobType) => (
          <div className="mb-4">
            <h5>{jobType?.type}</h5>
            {jobType?.subTypes?.map((subType) => (
              <div>
                <Checkbox
                  defaultSelected
                  value={subType}
                  isSelected={
                    !!JobSearchResource.getSearchParamByKeyId(
                      searchParams,
                      searchKey,
                      subType
                    )
                  }
                  onChange={(isSelected) => {
                    if (isSelected) {
                      setSearchDebounced({ was: subType });
                    } else {
                      deleteSearchDebounced({ was: subType });
                    }
                  }}
                  css={{ zIndex: 0 }}
                >
                  {subType}
                </Checkbox>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobTypeFilter;
