import React from "react";
import {
  ChatEngine,
  ChatList,
  ChatCard,
  NewChatForm,
  ChatFeed,
  // ChatHeader,
  IceBreaker,
  MessageBubble,
  IsTyping,
  ConnectionBar,
  NewMessageForm,
  ChatSettings,
  ChatSettingsTop,
  PeopleSettings,
  PhotosSettings,
  OptionsSettings,
} from "react-chat-engine";
import "../styles/ChatEngine.css";
// import ChatCard from "../components/chat/ChatCard";
import ChatHeader from "../components/chat/ChatHeader";

const NotificationsScreen = (): JSX.Element => {
  return (
    <>
      <ChatEngine
        height="100vh"
        projectID={process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}
        userName="chahin"
        userSecret="el_vaassen"
        renderChatHeader={(chat) => <ChatHeader chat={chat} />}
        renderChatSettingsTop={(creds, chat) => null}
        renderNewChatForm={(creds) => null}
        // renderChatSettings={(chatAppState) => null}
        renderChatCard={(chat, index) => (
          <ChatCard chat={chat} key={`${index}`} />
        )}
        renderChatFeed={(chatAppState) => <ChatFeed {...chatAppState} />}
      />
      {/* <ChatEngine
        // height="100vh"
        projectID={process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}
        userName="chahin"
        userSecret="el_vaassen"
        development={true}
        // Customize UI
        renderChatList={(chatAppState) => <ChatList {...chatAppState} />}
        renderChatCard={(chat, index) => (
          <ChatCard key={`${index}`} chat={chat} />
        )}
        renderNewChatForm={(creds) => <NewChatForm creds={creds} />}
        renderChatFeed={(chatAppState) => <ChatFeed {...chatAppState} />}
        renderChatHeader={(chat) => <ChatHeader />}
        renderIceBreaker={(chat) => <IceBreaker />}
        renderMessageBubble={(
          creds,
          chat,
          lastMessage,
          message,
          nextMessage
        ) => (
          <MessageBubble
            lastMessage={lastMessage}
            message={message}
            nextMessage={nextMessage}
            chat={chat}
          />
        )}
        renderIsTyping={(typers) => <IsTyping />}
        renderNewMessageForm={(creds, chatID) => <NewMessageForm />}
        renderChatSettings={(chatAppState) => (
          <ChatSettings {...chatAppState} />
        )}
        renderChatSettingsTop={(creds, chat) => <ChatSettingsTop />}
        renderPeopleSettings={(creds, chat) => <PeopleSettings />}
        renderPhotosSettings={(chat) => <PhotosSettings />}
        renderOptionsSettings={(creds, chat) => <OptionsSettings />}
      /> */}
    </>
  );
};

export default NotificationsScreen;
