export default class AuthorizationTokenStorage {
  private static TOKEN_KEY = "RECRUTIO_FRONTEND_WEB_API_AUTH_TOKEN";

  public static getToken = (): null | string =>
    localStorage.getItem(this.TOKEN_KEY);

  public static saveToken = async (token: string): Promise<boolean> =>
    new Promise((resolve) => {
      localStorage.setItem(this.TOKEN_KEY, token);
      resolve(true);
    });

  public static deleteToken = (): void => {
    localStorage.removeItem(this.TOKEN_KEY);
  };
}
