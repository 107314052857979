import { useMemo, useState, Fragment } from "react";
import { Loading, Pagination } from "@nextui-org/react";
import JobSearchFilter from "../components/search/JobSearchFilter";
import JobItem from "../components/jobs/JobItem";
import useJobSearch from "../hooks/useJobSearch";
import PageFooter from "../components/footers/PageFooter";
import { Drawer } from "@mui/material";
import { StellenAngebotInterface } from "../services/exports/Interfaces";
import JobDetails from "../components/jobs/JobDetails";
import JobSearch from "../components/search/JobSearch";
import IllustrationExplainer from "../components/IllustrationExplainer";
import SearchIllustration from "../assets/illustrations/SearchIllustration";
import useScrollPosition from "../hooks/useScrollPosition";

const HomeScreen = (): JSX.Element => {
  const { jobs, searching, setSearchDebounced } = useJobSearch();

  const { scrollPosition } = useScrollPosition();

  const [selectedJob, setSelectedJob] =
    useState<StellenAngebotInterface | null>(null);

  const showPagination: boolean = jobs?.maxErgebnisse > jobs?.size;

  const showScrollToTopButton: boolean = scrollPosition > window.innerHeight;

  const maxPage: number = Math.floor(
    jobs?.maxErgebnisse / jobs?.size > 100
      ? 100
      : jobs?.maxErgebnisse / jobs?.size
  );

  function scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const renderJobList = useMemo(() => {
    return (
      <div className="py-4">
        <h5 className="text-gray-500 mb-3 flex items-center">
          {searching
            ? "Suche Jobs..."
            : `${jobs?.maxErgebnisse ?? 0} Jobs gefunden`}
          {searching && (
            <div className="ml-2">
              <Loading size="sm" />
            </div>
          )}
        </h5>
        {jobs?.stellenangebote?.length > 0 ? (
          jobs?.stellenangebote?.map((job) => (
            <JobItem job={job} onClick={setSelectedJob} />
          ))
        ) : (
          <IllustrationExplainer
            illustration={<SearchIllustration className="h-16 w-16 mb-4" />}
            title="Keine Jobs gefunden"
            description="Bitte suche nach einem anderen Suchbergriff"
          />
        )}
        {showPagination && (
          <div className="bottom-0 py-4 flex justify-end">
            <Pagination
              total={maxPage}
              page={jobs?.page}
              onChange={(page) => setSearchDebounced({ page })}
            />
          </div>
        )}
      </div>
    );
  }, [jobs, searching]);

  const renderJobDetails = () => {
    const contentWidth: number = window.innerWidth / 2.2;
    return (
      <Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={!!selectedJob}
          onClose={() => setSelectedJob(null)}
        >
          <div
            className="h-screen bg-white"
            style={{ width: contentWidth }}
            key={selectedJob?.hashId}
          >
            {!!selectedJob && (
              <JobDetails job={selectedJob} contentWidth={contentWidth} />
            )}
          </div>
        </Drawer>
      </Fragment>
    );
  };

  const renderScrollToTopButton = (): JSX.Element | undefined => {
    return (
      showScrollToTopButton && (
        <button
          className="w-16 h-16 rounded-full bg-white shadow-lg fixed bottom-4 right-4 flex justify-center items-center border"
          onClick={scrollToTop}
        >
          <p>Top</p>
        </button>
      )
    );
  };

  return (
    <>
      <div className="sticky top-0 z-50">
        <JobSearch />
      </div>
      <div className="w-full bg-white grid grid-cols-6 mt-4">
        <div className="col-span-2 p-4 ">
          <JobSearchFilter />
        </div>
        <div className="col-span-4 px-4 pb-4">{renderJobList}</div>
      </div>
      <PageFooter />
      {renderJobDetails()}
      {renderScrollToTopButton()}
    </>
  );
};

export default HomeScreen;
