import { useState } from "react";
import { Input } from "@nextui-org/react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import classnames from "classnames";
import CountrySelector from "../languages/CountrySelector";
import DateTimeInput from "../inputs/DateTimeInput";
import StepFooter from "./StepFooter";
import StepHeader from "./StepHeader";

interface Props {
  step: number;
  activeStep: number;
  className: string;
  onEdit: (step: number) => void;
}

const PersonalInfoStep = (props: Props): JSX.Element => {
  const { step, activeStep, className, onEdit } = props;

  const [startDate, setStartDate] = useState(new Date());

  const isActive: boolean = step === activeStep;

  function onSave(): void {
    onEdit(null);
  }

  function onClose(): void {
    onEdit(null);
  }

  const renderSaved = (): JSX.Element => {
    return (
      <>
        <h4>Saved</h4>
      </>
    );
  };

  const renderEdit = (): JSX.Element => {
    return (
      <>
        <div className="grid grid-cols-2 gap-2">
          <Input
            label="Vorname"
            placeholder="Guillermo Rauch"
            className="mb-4"
          />
          <Input
            label="Nachname"
            placeholder="Guillermo Rauch"
            className="mb-4"
          />
        </div>
        <div className="grid grid-cols-2 gap-2">
          <Input label="Email" placeholder="Email" className="mb-4" />
          <DateTimeInput label="Geburtsdatum" />
        </div>
        <Input label="Adresse" placeholder="Guillermo Rauch" className="mb-4" />
        <div className="grid grid-cols-2 gap-2 mb-4">
          <Input label="Wohnort" placeholder="Guillermo Rauch" />
          <Input label="Zipcode" placeholder="Guillermo Rauch" />
        </div>
        <div className="mb-4">
          <p className="text-sm mb-2">Land</p>
          <CountrySelector />
        </div>
        <StepFooter onSave={onSave} onCancel={onClose} />
      </>
    );
  };

  return (
    <div className={classnames("flex flex-col w-full", className)}>
      <div className="mb-4">
        <StepHeader
          icon={<PermIdentityIcon />}
          title="Persönliche Daten"
          description="Wie können interessierte Arbeitgeber Kontakt mit dir aufnehmen?"
          onEdit={() => onEdit(step)}
        />
      </div>
      {isActive ? renderEdit() : renderSaved()}
    </div>
  );
};

export default PersonalInfoStep;
