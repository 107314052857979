import { useMemo, useState, useEffect } from "react";

import AuthContext from "../context/AuthContext";
import AuthorizationTokenStorage from "../services/auth/AuthorizationTokenStorage";

interface Props {
  children: JSX.Element;
}

const AuthProvider = (props: Props): JSX.Element => {
  const { children } = props;

  const [token, setToken] = useState<string>(
    AuthorizationTokenStorage.getToken()
  );

  useEffect(() => {
    if (token) {
      AuthorizationTokenStorage.saveToken(token);
    }
  }, [token]);

  const context = useMemo(
    () => ({
      token,
      setToken,
      deleteToken: () => {
        AuthorizationTokenStorage.deleteToken();
        setToken("");
      },
    }),
    [token]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
