import { NextUIProvider } from "@nextui-org/react";
import AppStack from "./navigation/AppStack";
import JobSearchProvider from "./providers/JobSearchProvider";
import TranslatorProvider from "./providers/TranslatorProvider";
import { store } from "./store";
import { Provider } from "react-redux";
import AuthProvider from "./providers/AuthProvider";
import GlobalModalsProvider from "./providers/GlobalModalsProvider";
import AppExplainer from "./components/animations/AppExplainer";

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <NextUIProvider>
        <AuthProvider>
          <TranslatorProvider>
            <GlobalModalsProvider>
              <JobSearchProvider>
                <>
                  <AppStack />
                  <AppExplainer />
                </>
              </JobSearchProvider>
            </GlobalModalsProvider>
          </TranslatorProvider>
        </AuthProvider>
      </NextUIProvider>
    </Provider>
  );
};

export default App;
